<template>
  <div>
    <h1 class="h3">Pricing</h1>
    <div class="card mb-3">
      <div class="card-body">
        <h2 class="h4 card-title">Currency Conversion</h2>
        <table class="table table-responsive table-borderless">
          <tbody>
            <tr>
              <td>USD to KES</td>
            </tr>
            <tr>
              <td>
                <input type="text" placeholder="amount" class="form-control" v-model="conversion.value">
              </td>
              <td><a href="#" class="btn btn-primary btn-sm" @click.prevent="updateConversion">Update</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <h2 class="h4 card-title">Signals Subscription</h2>
        <div class="row">
          <div class="col-md-3" v-for="(plan, i) in signals" :key="`signal-${i}`">
            <div class="card shadow text-center">
              <div class="px-2 py-3">
                <h3>{{ plan.months }} Month{{ plan.months == 1 ? '' : 's' }}</h3>
                <form @submit.prevent="update(plan)">
                  <div class="form-group">
                    <label>Amount(USD)</label>
                    <input type="text" v-model="plan.amount" placeholder="Amount" class="form-control">
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary px-2 py-2">Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h2 class="h4 card-title">Community Membership</h2>
        <div class="row">
          <div class="col-md-3" v-for="(plan, i) in community" :key="`commuity-${i}`">
            <div class="card shadow text-center">
              <div class="px-2 py-3">
                <h3>{{ plan.months }} Month{{ plan.months == 1 ? '' : 's' }}</h3>
                <form @submit.prevent="update(plan)">
                  <div class="form-group">
                    <label>Amount(USD)</label>
                    <input type="text" v-model="plan.amount" placeholder="Amount" class="form-control">
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary px-2 py-2">Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      signals: [],
      community: [],
      conversion: {}
    }
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get('/api/v1/admin/pricing').then(response => {
        this.signals = response.data.signals
        this.community = response.data.community
        this.conversion = response.data.conversion
        this.$loader.stop()
      })
    },

    update(plan) {
      this.$axios.put(`/api/v1/admin/pricing/${plan.id}`, { amount: plan.amount }).then(() => {
        this.fetch()
      })
    },

    updateConversion() {
      this.$axios.post(`/api/v1/admin/pricing/conversion`, this.conversion).then(() => {
        this.$toasted.show('Conversion has been updated', { duration: 5000 })
      })
    }
  }
}
</script>